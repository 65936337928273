import { get, post, put, Delete } from "@/api/axios";

// 白名单设置-列表
export const whiteList = (params) => get("/web/scan/white/list", params);

// 白名单设置-区域-列表
export const regionList = (params) =>
  get("/web/scan/white/region/list/" + params.id, "");

// 白名单设置-会员-列表
export const userList = (params) => get("/web/scan/white/user/list", params);

// 白名单设置-新增-区域-列表
export const addRegionList = (params) =>
  get("/web/scan/white/add/region/list", params);

// 白名单设置-新增
export const whiteAdd = (params) => post("/web/scan/white/add", params);

// 白名单设置-删除
export const deleteWhite = (params) =>
  Delete("/web/scan/white/delete/" + params.id, "");

// 白名单设置-查看
export const whiteView = (params) =>
  get("/web/scan/white/view/" + params.id, "");

// 白名单设置-修改
export const whiteUpdate = (params) => put("/web/scan/white/update", params);

// 白名单设置-状态-修改
export const whiteStateUpdate = (params) =>
  put("/web/scan/white/state/update", params);

// 开锁记录-列表
export const openList = (params) => get("/web/scan/open/list", params);

// 开锁记录-导出
export const openExport = (params) => get("/web/scan/open/export", params);
